/* eslint-disable max-len */
import React from 'react';
import whatsapp from '../images/whatsapp.png';

class BtnPeqWhatsApp extends React.Component {
  render() {
    return (
      <button
        id="whats"
        className="fixed w-[80px] bottom-3 right-3"
        onClick={ () => window.open(
          'https://api.whatsapp.com/send?phone=5511980203190&text=Ol%C3%A1,%20estou%20interessado%20em%20pe%C3%A7as%20automotivas!',
        ) }
      >
        <img
          id="whats"
          src={ whatsapp }
          alt="btnWhats"
        />
      </button>
    );
  }
}

export default BtnPeqWhatsApp;
